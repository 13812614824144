<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label for="username">เลือกธนาคาร</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                placeholder="เลือกธนาคาร"
              />
            </div>
          </div> -->
          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="getWithdraw()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              @click="search = false, tel = null , member = null"
            >
              ถอนเครดิต
            </b-button>
            <b-button
              v-b-modal.modal-2
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-danger"
              @click="search = false, tel = null , member = null"
            >
              ยึดเครดิต
            </b-button>
          </div>
          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <!-- <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    :disabled="!search_type"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon"
                    block
                    @click="Export()"
                  >
                    Export
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(before_balance)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.before_balance ? Commas(data.item.before_balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(balance)="data">
          <p class="font-weight-bolder text-success mb-0">
            {{ data.item.balance ? Commas(data.item.balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(amount)="data">
          <p class="font-weight-bolder text-danger mb-0">
            {{ data.item.amount ? Commas(data.item.amount.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'waiting'"
              variant="light-primary"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'processing'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'hold_success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'manual_success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'restore'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'waiting_approve'"
              variant="light-secondary"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'error'"
              variant="light-danger"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'scanQR'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>

          </div>
        </template>
        <template #cell(username)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.username }}
          </span>
          <span
            v-else-if="data.item.vip"
            style="color: blue"
          >
            {{ data.item.username }}
          </span>
          <span v-else>
            {{ data.item.username }}
          </span>
        </template>
        <template #cell(fullname)="data">
          <span
            v-if="data.item.blacklist"
            style="color:red"
          >
            {{ data.item.fullname }}
          </span>
          <span
            v-else-if="data.item.vip"
            style="color:blue"
          >
            {{ data.item.fullname }}
          </span>
          <span v-else>
            {{ data.item.fullname }}
          </span>
        </template>
        <!-- Column: Detail -->
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="FileTextIcon"
              size="16"
              class="mx-1"
              -->
            <b-button
              v-b-tooltip.hover.v-info
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.id}})"
            >
              <i class="fas fa-address-card" />
            </b-button>
            <!-- <b-tooltip
              title="History Deposit & Withdraw"
              :target="`invoice-row-${data.item.username}-preview-icon`"
            /> -->
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(bank)="data">
          <div>
            <div class="text-center">
              <img
                v-if="data.item.bank === 'truewallet'"
                :src="`/bankIcon/${data.item.bank_path_photo ? data.item.bank_path_photo : 'true.svg'}`"
                alt="bank"
                class="bank-icon"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              >
              <img
                v-else-if="data.item.bank === 'crypto'"
                :src="`/bankIcon/${data.item.bank_path_photo }`"
                alt="bank"
                class="bank-icon"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              >
              <img
                v-else
                :src="`/bankIcon/${data.item.bank_path_photo ? data.item.bank_path_photo : 'dollar.png'}`"
                alt="bank"
                class="bank-icon"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              ><br>
              <small class="text-info"><u>{{ data.item.acc_no }}</u></small><br>
              <small>{{ data.item.cus }}</small>
            </div>
          </div>
        </template>

        <template #cell(frome)="data">
          <div>
            <div class="text-center">
              <img
                v-if="data.item.frome === 'kbank'"
                :src="`/bankIcon/KBANK.png`"
                alt="bank"
                class="bank-icon"
                :style="`background:#009345;`"
              >
              <img
                v-else-if="data.item.frome === 'scb'"
                :src="`/bankIcon/scb.png`"
                alt="bank"
                class="bank-icon"
                :style="`background:#4c2786;`"
              >
              <!-- <img
                v-else
                :src="`/bankIcon/dollar.png`"
                alt="bank"
                class="bank-icon"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              > -->
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-if="data.item.status === 'waiting_approve'"
              v-b-tooltip.hover.v-primary
              title="อนุมัติ"
              variant="gradient-primary"
              size="sm"
              @click="ConfirmApprove(data.item,'approve')"
            >
              อนุมัติ
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'waiting_approve' || data.item.status === 'error' || data.item.status === 'processing'"
              v-b-tooltip.hover.v-dark
              variant="gradient-dark"
              title="คืนเงิน"
              size="sm"
              @click="Confirm(data.item,'restore')"
            >
              คืนเงิน
            </b-button>
             &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'waiting_approve' || data.item.status === 'error' || data.item.status === 'processing' || data.item.status === 'waiting'"
              v-b-tooltip.hover.v-info
              title="ถอนมือ"
              variant="gradient-info"
              size="sm"
              @click="Confirm(data.item,'manual')"
            >
              ถอนมือ
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'scanQR'"
              v-b-tooltip.hover.v-warning
              title="KbankQR"
              variant="gradient-warning"
              size="sm"
              @click="ShowQR(data.item)"
            >
              KbankQR
            </b-button>
          </div>
        </template>
        <template #cell(manual)="data">
          <div class="text-nowrap">
            <b-button
              v-if="data.item.status === 'error' || data.item.status === 'processing' || data.item.status === 'waiting' || data.item.status === 'waiting_approve'"
              v-b-tooltip.hover.v-info
              title="ถอนมือ"
              variant="gradient-info"
              size="sm"
              @click="Confirm(data.item,'manual')"
            >
              ถอนมือ
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'error' || data.item.status === 'processing'"
              v-b-tooltip.hover.v-danger
              title="รีเซ็ท"
              variant="gradient-danger"
              size="sm"
              @click="Confirm(data.item,'reset')"
            >
              รีเซ็ท
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'error' || data.item.status === 'waiting_approve' || data.item.status === 'processing' "
              v-b-tooltip.hover.v-dark
              title="คืนเงิน"
              variant="gradient-dark"
              size="sm"
              @click="Confirm(data.item,'restore')"
            >
              คืนเงิน
            </b-button>
                    &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'waiting_approve' || data.item.status === 'error'"
              v-b-tooltip.hover.v-dark
              title="ยึดเครดิต"
              variant="gradient-dark"
              size="sm"
              @click="Confirm(data.item,'holdcredit')"
            >
              ยึดเครดิต
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getWithdraw()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getWithdraw()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="ถอนเครดิต"
        ok-title="ถอนเครดิต"
        @ok="submit"
      >
        <b-overlay
          :show="show2"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="d-flex">
            <b-form-input
              id="vi-first-name"
              v-model="tel"
              class="col-9 mr-1"
              name="tel"
              placeholder="กรอกยูสเซอร์เพื่อค้นหา"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="col-2.5 btn-icon"
              block
              @click="getUsersSearch()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </b-button>
          </div><hr>
          <div>
            <span
              v-if="!member && search === true"
              style="color :red;"
            >
              ไม่พบข้อมูล
            </span>
            <span
              v-if="member && search === true"
              style="color :green;"
            >
              {{ member.name }} {{ member.surname }} : {{ member.username }}
            </span>
          </div>
          <div v-if="member && search === true">
            เลือกช่องทางโอน
            <b-form-radio
              v-model="Selected"
              plain
              name="some-radios3"
              value="bank"
            >
              เลขที่บัญชี : {{ member.acc_no }}
            </b-form-radio>
            <b-form-radio
              v-model="Selected"
              plain
              name="some-radios3"
              value="truewallet"
            >
              True Money Wallet : {{ member.trueaccno }}
            </b-form-radio>
            <br>
          </div>

          <b-form-group
            label="จำนวนเงินที่ถอน"
            label-for="vi-first-name"
          >

            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />

          </b-form-group>
        </b-overlay>
      </b-modal>
      <b-modal
        id="modal-2"
        title="ยึดเครดิต"
        ok-title="ยึดเครดิต"
        @ok="submitHold"
      >
        <b-overlay
          :show="show2"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-form-group>
            <div class="d-flex">
              <b-form-input
                id="vi-first-name"
                v-model="tel"
                class="col-9 mr-1"
                name="tel"
                type="number"
                placeholder="กรอกยูสเซอร์เพื่อค้นหา"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="col-2.5 btn-icon"
                block
                @click="getUsersSearch()"
              >
                <feather-icon icon="SearchIcon" />
                ค้นหา
              </b-button>
            </div>
            <div>
              <span
                v-if="!member && search === true"
                style="color :red;"
              >
                ไม่พบข้อมูล
              </span>
              <span
                v-if="member && search === true"
                style="color :green;"
              >
                {{ member.name }} {{ member.surname }} : {{ member.username }}
              </span>
            </div>
            <br>
            <b-form-group
              label="จำนวนเงินที่ต้องการยึด"
              label-for="vi-first-name"
            >

              <b-form-input
                id="vi-first-name"
                v-model="amount"
                placeholder=""
              />

            </b-form-group>
          </b-form-group>
        </b-overlay>
      </b-modal>
      <b-modal
        ref="modal-kbank-qr"
        title="kbankQR"
        ok-title="โอนเรียบร้อย"
        cancel-title="ยกเลิก"
        @ok="submitKbankQr()"
      >
        <h4
          style="color :rgb(155, 114, 2);"
        >
          {{ kqr_timeout === 0 ? 'หมดเวลา': kqr_timeout }}
        </h4>
        <b-img
          v-if="KbankQr.kbank_qrBase64 && kqr_timeout !== 0"
          :src="KbankQr.kbank_qrBase64"
          alt=""
          fluid
        />
        <span
          v-if="KbankQr.kbank_qrBase64 && kqr_timeout !== 0"
          style="color :red;"
        >
          ***หลังจากยืนยันเรียบร้อย กรุณากดปุ่มโอนเรียบร้อยค่ะ***
        </span>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  // BRow,
  // BCol,
  VBTooltip, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BFormInput,
  BButton, BCardBody, VBToggle, BOverlay, BIconController, BBadge, BFormRadio,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import BUID from 'uniquebrowserid'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
    BFormRadio,
    // flatPickr,
    BImg,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'สถานะรอยืนยัน' },
        { value: 3, text: 'สถานะกำลังดำเนินการ' },
        { value: 4, text: 'สถานะคืนเงิน' },
        { value: 5, text: 'สถานะไม่สำเร็จ' },
        { value: 6, text: 'สถานะยึดเครดิต' },
      ],
      search_type: null,
      search_val: null,
      show: false,
      show2: false,
      Selected: null,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'detail', label: 'รายละเอียดลูกค้า' },
        // { key: 'frome', label: 'จากธนาคาร' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'tel', label: 'เบอร์โทร' },
        // { key: 'cus', label: 'ชื่อลูกค้า' },
        { key: 'bankname', label: 'ธนาคาร' },
        { key: 'acc_no', label: 'เลขบัญชี' },
        { key: 'before_balance', label: 'จำนวนเงินก่อนถอน' },
        { key: 'amount', label: 'เครดิตที่ถอน' },
        { key: 'balance', label: 'ยอดเครดิตหลังถอน' },
        { key: 'addbyName', label: 'แจ้งถอนโดย' },
        { key: 'approveby', label: 'อนุมัติโดย' },
        { key: 'approve_time', label: 'อนุมัติเมื่อ' },
        { key: 'manualby', label: 'ถอนมือโดย' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz("Asia/Bangkok")
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'อนุมัติ/คืนเงิน' },
        { key: 'manual', label: 'ถอนมือ/รีเซ็ต/คืนเงิน' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      tel: null,
      search: false,
      dateStart: moment().tz("Asia/Bangkok").format('YYYY-MM-DD'),
      dateEnd: moment().tz("Asia/Bangkok").format('YYYY-MM-DD'),
      resmsg: null,
      KbankQr: {
        kbank_qrBase64: '',
      },
      kqr_interval: null,
      kqr_timeout: null,
      AgLog: {
        ip: null,
        latitude: '',
        longitude: '',
        buid: new BUID().completeID(),
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getWithdraw()
    }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // this.totalRows = this.items.length
    this.getWithdraw()
    this.LogAdmin()
  },
  methods: {
    Export() {
      this.$http
        .get(`withdraw/export/${this.dateStart}/${this.dateEnd}`, { responseType: 'blob' })
        .then(response => {
          // console.log(response.data)
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([response.data]),
          )
          link.setAttribute('download', `รายการถอนวันที่ ${this.dateStart} ถึง ${this.dateEnd}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => console.log(error))
    },
    GetLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude } = position.coords
          const { longitude } = position.coords
          this.AgLog.latitude = latitude
          this.AgLog.longitude = longitude
        })
      }
    },
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.AgLog.ip = response.data
        })
        .catch(error => console.log(error))
    },
    async LogAdmin() {
      await this.GetLocation()
      await this.GetIp()
      const params = {
        on_page: 'withdraw',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
        buid: this.AgLog.buid,
      }
      this.$http.get('/admin/log', { params })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getUsersSearch() {
      this.show2 = true
      const obj = {
        username: this.tel,
      }
      this.$http
        .post('/users/search/username', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    getWithdraw() {
      this.show = true
      const arry = []
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/withdraw', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          // console.log(response.data)
          this.depositdata.forEach(items => {
            if (items.status === 'waiting_approve') {
              arry.push(items)
            }
          })
          // localStorage.setItem('alertwit', JSON.stringify(arry))
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    // getAdmin(addby, index) {
    //   this.$http
    //     .get(`/admin/show/${addby}`)
    //     .then(response => {
    //       // console.log(response.data.name)
    //       this.show = false
    //       this.depositdata[index].approveby = response.data.name
    //     })
    //     .catch(error => console.log(error))
    // },
    Confirm(data, type) {
      if (type === 'approve') {
        this.showtext = 'อนุมัติ'
      } else if (type === 'manual') {
        this.showtext = 'ถอนมือ'
      } else if (type === 'restore') {
        this.showtext = 'คืนเงิน'
      } else if (type === 'reset') {
        this.showtext = 'รีเซ็ท'
      } else if (type === 'holdcredit') {
        this.showtext = 'ยึดเครดิต'
      }
      this.$swal({
        title: 'แจ้งเตือน ?',
        // eslint-disable-next-line no-undef
        text: `ยืนยันที่จะ ${this.showtext} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'approve') {
            this.approve(data)
          } else if (type === 'manual') {
            this.manual(data)
          } else if (type === 'restore') {
            this.restore(data)
          } else if (type === 'reset') {
            this.approve(data)
          } else if (type === 'holdcredit') {
            this.holdcredit(data)
          }
        } else {
          this.show = false
          this.$swal('ยกเลิก', `ยกเลิกการ${this.showtext}`, 'error')
        }
      })
    },
    async  ConfirmApprove(data, type) {
      if (type === 'approve') {
        this.showtext = 'อนุมัติ'
      }
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        wid: data._id,
      }

      await this.$http
        .post('/scb/checkNameWit', obj)
        .then(response => {
          console.log(response.data)
          if (response.data.status === true) {
            this.resmsg = `ยืนยันที่จะอนุมัติโอนให้กับ <br><b style="color:red;">${response.data.msg.accountToName} </b> หรือไม่ ?`
          } else {
            this.resmsg = `ไม่สามารถแสดงชื่อบัญชีผู้รับโอนได้ <br> ${response.data.msg}`
          }
        })
        .catch(error => console.log(error))
      this.$swal({
        title: 'แจ้งเตือน ?',
        // eslint-disable-next-line no-undef
        html: this.resmsg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'approve') {
            this.approve(data)
          } else if (type === 'manual') {
            this.manual(data)
          } else if (type === 'restore') {
            this.restore(data)
          } else if (type === 'reset') {
            this.approve(data)
          } else if (type === 'holdcredit') {
            this.holdcredit(data)
          }
        } else {
          this.show = false
          this.$swal('ยกเลิก', `ยกเลิกการ${this.showtext}`, 'error')
        }
      })
    },
    submit() {
      if (this.Selected === 'truewallet') {
        this.member.acc_no = this.member.trueaccno
        this.member.bank = 'truewallet'
      }
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
        acc_no: this.member.acc_no,
        bank: this.member.bank,
      }
      this.$http
        .post('/withdraw/store', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
          this.show = false
        })
    },
    approve(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/approve', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    restore(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/restore', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('คืนเงิน สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    holdcredit(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/holdcredit', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ยึดเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    manual(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/manual', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    submitHold() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
      }
      this.$http
        .post('/withdraw/holdstore', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    ShowQR(item) {
      clearInterval(this.kqr_interval)
      if (item.kbank_qrBase64 && this.CalDifTime(item.kbank_timestamp) !== 0) {
        this.$refs['modal-kbank-qr'].show()
        this.KbankQr = item
        this.kqr_timeout = this.CalDifTime(item.kbank_timestamp)
        this.kqr_interval = setInterval(() => {
          this.kqr_timeout = this.CalDifTime(item.kbank_timestamp)
        }, 1000)
      } else {
        this.$swal('QR Code หมดอายุแล้ว', '', 'error')
      }
    },
    submitKbankQr() {
      console.log(this.KbankQr)
      // eslint-disable-next-line no-underscore-dangle
      this.$http.get('/withdraw/kbankqrstatus', { params: { ID: this.KbankQr._id } })
        .then(() => {
          this.$refs['modal-kbank-qr'].hide()
          this.$swal('สำเร็จ', 'รอระบบตรวจสอบรายการสักครู่ค่ะ', 'success')
          this.getWithdraw()
        })
    },
    CalDifTime(val) {
      const targetDate = moment(val).tz("Asia/Bangkok").add(6, 'minutes')
      const currentDate = moment().tz("Asia/Bangkok")
      const duration = moment.duration(targetDate.diff(currentDate))

      const minutes = duration.minutes()
      const seconds = duration.seconds()
      if (minutes < 0 || seconds < 0) {
        return 0
      }
      return `เหลือเวลา ${minutes} นาที ${seconds} วินาที`
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
      this.tel = null
      this.search = false
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
